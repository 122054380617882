import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class SlideshowArtwork extends Artwork {
  init() {
    super.init();
    this.initArtwork();
  }
  initChildren() {
    // this.parent.style.zIndex = 1;

    let newEl = document.createElement("div");
    this.container.append(newEl);
    newEl.classList.add("container-full");

    let newArtwork = this.helpers.getChildArtwork(this.data.children[0], newEl);
    this.child = { el: newEl, obj: newArtwork };
  }
  initArtwork() {
    this.images = [];

    this.data.props.images.forEach((img) => {
      let imgEl = document.createElement("img");
      imgEl.src = img;
      imgEl.classList.add("fullsize");
      imgEl.classList.add("imagefill");
      imgEl.style.opacity = 0;
      imgEl.style.transition = "6s opacity ease";
      this.el.append(imgEl);
      this.images.push(imgEl);
    });
  }
  initSlideshow() {
    this.activeImg = this.images.length - 1;
    this.images[this.activeImg].style.opacity = 1;
    setTimeout(() => {
      this.nextImage();
    }, 100);
    this.timer = window.setInterval(() => {
      this.nextImage();
    }, 6000);
  }
  nextImage() {
    this.images[this.activeImg].style.opacity = 0;
    if (this.activeImg < this.images.length - 1) {
      this.activeImg++;
    } else {
      this.activeImg = 0;
    }
    this.images[this.activeImg].style.opacity = 1;
  }
  animateOut() {
    window.clearInterval(this.timer);
    super.animateOut();
  }
  start() {
    this.showArrowInS(10);
    super.start();
    this.initChildren();
    // this.child = this.children[0];
    this.initSlideshow();

    this.el.addEventListener("click", () => {
      this.animateOut();
    });
  }
  destroy() {
    super.destroy();
  }
}
