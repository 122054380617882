import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class ImagewallArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    const images = [];
    const imgSrc = this.data.props.images;
    const thbSrc = this.data.props.thb;
    for (let i = 1; i <= this.data.props.count; i++) {
      let num = i.toString().padStart(2, "0");
      let src = imgSrc.replace("##", num);
      let thb = thbSrc.replace("##", num);
      images.push({
        src: src,
        thb: thb,
      });
    }
    this.imageContainer = document.createElement("div");
    this.imageContainer.classList.add("imagewall-container");
    this.el.append(this.imageContainer);

    this.el.style.backgroundColor = "#fff";

    images.forEach((img) => {
      let imgEl = document.createElement("img");
      imgEl.src = img.src;
      this.imageContainer.append(imgEl);
    });
    this.i = 0;

    // this.xPos = Math.round(Math.random() * -100);
    // this.yPos = Math.round(Math.random() * -100);
    this.xPos = -50;
    this.yPos = -50;
    this.imageContainer.style.transform = `translate(${this.xPos}%, ${this.yPos}%)`;

    this.mouseDeltaX = 0;
    this.mouseDeltaY = 0;
    this.listenerMove = this.el.addEventListener("mousemove", (e) => {
      this.mouseDeltaX = e.clientX - window.innerWidth / 2;
      this.mouseDeltaY = e.clientY - window.innerHeight / 2;
    });
    this.listenerOut = this.el.addEventListener("mouseout", (e) => {
      this.mouseDeltaX = 0;
      this.mouseDeltaY = 0;
    });
  }
  animate() {
    this.xPos += this.mouseDeltaX / -10000;
    this.yPos += this.mouseDeltaY / -10000;

    if (this.xPos > -4) this.xPos = -4;
    if (this.xPos < -100) this.xPos = -100;
    if (this.yPos > -4) this.yPos = -4;
    if (this.yPos < -100) this.yPos = -100;

    this.imageContainer.style.transform = `translate(${this.xPos}%, ${this.yPos}%)`;

    requestAnimationFrame(() => {
      this.animate();
    });
  }
  start() {
    super.start();
    this.animate();
    this.initRandomChild();
    this.showArrowInS(10);

    setTimeout(() => {
      this.imageContainer.classList.add("visible");
    }, 500);
  }
  destroy() {
    this.el.removeEventListener("mousemove", this.listenerMove);
    this.el.removeEventListener("mouseout", this.listenerOut);
    super.destroy();
  }
}
