import helpers from "./helpers";
import anime from "animejs/lib/anime.es.js";
export default class Artwork {
  constructor(_el, _data) {
    this.parent = _el;
    this.container = document.querySelector("#app");
    this.helpers = helpers;
    this.data = _data;
    // console.log("🔨 construct " + this.data.id);
    this.init();
  }
  init() {
    // console.log("👋 init " + this.data.id);
    this.el = document.createElement("div");
    this.el.classList.add("artwork");
    this.parent.append(this.el);
  }
  hideCursor() {
    // console.log("hide cursor");
    document.body.classList.add("nocursor");
  }
  showArrowInS(s) {
    this.arrowTimeout = window.setTimeout(() => {
      this.showArrow();
    }, s * 1000);
  }
  showArrow() {
    const arrow = document.createElement("div");
    arrow.classList.add("arrow");
    this.el.append(arrow);
  }
  initChildren() {
    // console.log("👶 init children of " + this.data.id);
    this.children = [];
    const randomChild =
      this.data.children[Math.floor(Math.random() * this.data.children.length)];
    // this.data.children.forEach((child, index) => {
    let newEl = document.createElement("div");
    this.container.append(newEl);
    newEl.classList.add("fullsize");

    let newArtwork = this.helpers.getChildArtwork(randomChild, newEl);
    this.children.push({ el: newEl, obj: newArtwork });
    // });
    this.child = this.children[0];
  }
  initChildById(_id) {
    this.children = [];
    const child = this.data.children[_id];
    // console.log(`👶 ${this.data.id} child: ${child}`);
    if (!child) return;
    const newEl = document.createElement("div");
    this.container.append(newEl);
    newEl.classList.add("fullsize");

    let newArtwork = this.helpers.getChildArtwork(child, newEl);
    this.children.push({ el: newEl, obj: newArtwork });
  }
  initRandomChild() {
    this.initChildById(Math.floor(Math.random() * this.data.children.length));
  }
  enableClickProceed() {
    setTimeout(() => {
      this.el.addEventListener(
        "click",
        (e) => {
          e.preventDefault();
          this.animateOut();
        },
        { once: true }
      );
    }, 4000);
  }
  start() {
    // console.log("🚀 start " + this.data.id);
    this.parent.style.zIndex = 100;
    // document.body.classList.remove("nocursor");

    if (this.data.props.inverted == "true") {
      document.body.classList.add("inverted");
    } else {
      document.body.classList.remove("inverted");
    }
  }
  destroy() {
    // console.log("🧨 destroy " + this.data.id);
    document.body.classList.remove("nocursor");
    this.parent.remove();
  }
  animateOut() {
    this.parent.classList.add('destroyed');
    
    if (this.arrowTimeout) {
      window.clearTimeout(this.arrowTimeout);
    }

    if (!this.child) {
      this.child = this.children[0];
    }
    anime({
      targets: this.el,
      opacity: 0,
      easing: "easeInOutExpo",
      duration: 1000,
    }).finished.then(() => {
      this.destroy();
      this.child.obj.start();
    });
  }
}
