import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class RoundVideoArtwork extends Artwork {
  init() {
    super.init();
    super.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    this.video =
      this.data.props.files[
        Math.floor(Math.random() * this.data.props.files.length)
      ];
    this.videoEl = document.createElement("video");
    this.videoEl.loop = true;
    this.videoEl.muted = true;
    this.videoEl.src = this.video;
    this.videoEl.classList.add("round-video");
    this.videoEl.style.pointerEvents = "none";
    const randLeft = Math.round(Math.random());
    if (randLeft) {
      this.videoEl.style.left = "20%";
    } else {
      this.videoEl.style.right = "20%";
    }

    this.videoEl.style.top =
      Math.round(
        Math.random() * (window.innerHeight - window.innerWidth * 0.3)
      ) + "px";
    this.el.append(this.videoEl);
  }
  start() {
    this.showArrowInS(5);
    super.start();
    this.initChildren();

    this.videoEl.play();
  }
}
