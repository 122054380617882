import Artwork from "../Artwork";
import anime from "animejs";
export default class WordsArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    this.image =
      this.data.props.files[
        Math.floor(Math.random() * this.data.props.files.length)
      ];

    this.word = document.createElement("div");
    this.curtain = document.createElement("div");

    this.word.classList.add("fullsize");
    this.word.classList.add("words-img");
    this.curtain.classList.add("fullsize");
    this.curtain.classList.add("words-curtain");

    this.word.style.backgroundImage = `url(${this.image})`;

    this.el.append(this.word);
    this.el.append(this.curtain);

    this.moveListener = this.el.addEventListener("mousemove", (e) => {
      this.handleMouseMove(e);
    });
    this.curtainStyles = {
      scaleY: [0.5, 0.5],
    };

    this.initDoor();

    requestAnimationFrame(() => {
      this.animate();
    });
  }

  initDoor() {
    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    this.poly = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    this.mask = document.createElementNS("http://www.w3.org/2000/svg", "mask");
    this.maskPoly = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    this.maskBgPoly = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    this.mask.setAttribute("id", "myMask");
    this.mask.append(this.maskBgPoly);
    this.mask.append(this.maskPoly);
    this.svg.classList.add("words-svg");
    this.svg.classList.add("fullsize");
    this.svg.setAttribute("preserveAspectRatio", "none");
    this.svg.setAttribute("viewBox", `0 0 800 600`);
    this.poly.setAttribute("points", "0 0 800 0 800 600 0 600");
    this.poly.setAttribute("fill", "#000000");
    this.poly.setAttribute("mask", "url(#myMask)");
    // this.maskPoly.setAttribute("points", "0 0 800 0 800 600 0 600");
    this.maskPoly.setAttribute("points", "0 0 800 0 800 600 0 600");
    this.maskPoly.setAttribute("fill", "#000000");
    this.maskBgPoly.setAttribute("points", "0 0 800 0 800 600 0 600");
    this.maskBgPoly.setAttribute("fill", "#ffffff");
    this.svg.append(this.mask);
    this.svg.append(this.poly);
    this.el.append(this.svg);
  }

  handleMouseMove(e) {
    let mouseX = e.clientX / window.innerWidth;
    let mouseY = e.clientY / window.innerHeight;

    this.curtainStyles.scaleY[1] = mouseY;
  }
  animate() {
    this.curtainStyles.scaleY[0] +=
      (this.curtainStyles.scaleY[1] - this.curtainStyles.scaleY[0]) / 10;
    this.curtain.style.transform = `translateY(-50%) scaleY(${this.curtainStyles.scaleY[0]})`;

    requestAnimationFrame(() => {
      this.animate();
    });
  }

  animateOut() {
    anime({
      targets: this.maskPoly,
      points: [
        {
          value: "200 600 600 600 800 600 0 600",
        },
      ],
      easing: "easeOutExpo",
      duration: 3000,
    }).finished.then(() => {
      this.destroy();
      this.child.obj.start();
    });
  }
  destroy() {
    this.el.removeEventListener("mousemove", this.moveListener);
    super.destroy();
  }
  start() {
    this.initChildren();
    this.showArrowInS(5);
    super.start();
  }
}
