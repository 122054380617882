import Artwork from "../Artwork";

export default class MemoryArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
    
    this.images = this.data.props.files;
    shuffleArray(this.images);
    this.imageEls = [];
    this.el.classList.add("memory-wrap");

    this.grid = document.createElement("div");
    this.grid.classList.add("memory-grid");
    this.el.append(this.grid);

    this.images.forEach((src) => {
      let img = new Image();
      img.src = src;
      this.imageEls.push(img);

      img.addEventListener("mouseover", (e) => {
        e.currentTarget.classList.add("visible");
      });
      img.addEventListener("mouseout", (e) => {
        e.currentTarget.classList.remove("visible");
      });

      this.grid.append(img);
    });

    this.alignItems();
    window.addEventListener("resize", () => {
      this.alignItems();
    });
  }
  alignItems() {
    const { width, height } = this.el.getBoundingClientRect();
    let rowCount = 1;
    let colCount = 3;

    let sqSize = width / 3;
    while ((rowCount + 1) * sqSize < height) {
      rowCount++;
    }
    if (rowCount > 4) rowCount = 4;

    if (rowCount <= 1) {
      colCount = 4;
      sqSize = width / colCount;
      while ((rowCount + 1) * sqSize < height) {
        rowCount++;
      }
      if (rowCount > 4) rowCount = 4;
    }

    this.imageEls.forEach((img, i) => {
      img.style.width = `${sqSize}px`;
      img.style.height = `${sqSize}px`;
      if (i >= rowCount * colCount) {
        img.style.display = "none";
      } else {
        img.style.display = "block";
      }
    });

    this.grid.style.height = `${sqSize * rowCount}px`;
  }
  start() {
    this.showArrowInS(5);
    super.start();
    this.initRandomChild();
    this.child = this.children[0];
  }
}
