import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class ZoomoutArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    this.image = this.data.props.files[
      Math.floor(Math.random() * this.data.props.files.length)
    ];
    this.imgEl = document.createElement("img");
    this.imgEl.src = this.image;
    this.imgEl.classList.add("zoom-img");
    this.el.append(this.imgEl);
    this.el.style.backgroundColor = "#ebeff2";
  }
  animateOut() {
    this.initChildren();
    const child = this.children[0];

    if (!this.children) {
      this.destroy();
    } else {
      anime({
        targets: this.el,
        opacity: 0,
        easing: "easeInOutExpo",
        duration: 2000,
      }).finished.then(() => {
        child.obj.start();
        this.destroy();
      });
    }
  }
  start() {
    super.start();
    this.imgEl.classList.add("animated");
  }
}
