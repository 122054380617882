import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class ChairArtwork extends Artwork {
  init() {
    super.init();
    this.initArtwork();
  }
  initArtwork() {
    this.image = null;
    this.el.classList.add("chair");
    this.clickCount = 0;
    this.hasEnded = false;
    this.hasClicked = false;

    this.initText();
    this.initChair();

    this.insta = new Image();
    this.insta.src = this.data.props.installation;
    this.insta.classList.add("chair__installation");
    this.el.append(this.insta);
  }
  initChair() {
    const chairWrap = document.createElement("div");
    chairWrap.classList.add("chair__chairwrap");
    chairWrap.classList.add("fullsize");
    this.el.append(chairWrap);

    const chairImg = document.createElement("div");
    chairImg.classList.add("chair__chairimg");
    this.el.append(chairImg);

    this.chair = new Image();
    this.chair.src = this.data.props.chair;
    this.chair.classList.add("chair__chair");
    this.chair.classList.add("visible");
    chairImg.append(this.chair);
  }
  handleMouseMove(e) {
    let mouseX = e.clientX / window.innerWidth;
    let mouseY = e.clientY / window.innerHeight;

    this.chairStyles.scaleY[1] = 0.75 - mouseY / -4;
    this.chairStyles.skew[1] = mouseX * 60 - 30;
  }
  animate() {
    this.chairStyles.scaleY[0] +=
      (this.chairStyles.scaleY[1] - this.chairStyles.scaleY[0]) / 10;
    this.chairStyles.skew[0] +=
      (this.chairStyles.skew[1] - this.chairStyles.skew[0]) / 10;
    this.chair.style.transform = `scaleY(${this.chairStyles.scaleY[0]}) skew(${this.chairStyles.skew[0]}deg)`;

    requestAnimationFrame(() => {
      this.animate();
    });
  }
  initText() {
    const texts = [
      "A white moon,",
      "a porcelain plate,",
      "a reflection in the windowpane.",
      "The shadow of a child’s chair extends up to the ceiling;",
      "next to it, a wooden chair",
      "is as large as the shadow.",
      "On the screen, letters pass across the moon:",
      "V E R S O.",
    ];
    this.textEls = [];
    const textWrap = document.createElement("div");
    textWrap.classList.add("chair__textwrap");
    textWrap.classList.add("fullsize");
    this.el.append(textWrap);

    texts.forEach((txt) => {
      let textEl = document.createElement("span");
      textEl.innerText = txt;
      textWrap.append(textEl);
      this.textEls.push(textEl);
    });

    this.el.addEventListener("click", (e) => {
      this.handleClick(e);
    });
  }

  handleClick(e) {
    this.hasClicked = true;
    if (this.hasEnded) {
      this.animateOut();
    }

    if (this.clickCount >= this.textEls.length) {
      this.insta.classList.add("visible");
      this.chair.classList.remove("visible");
      this.textEls.forEach((el) => {
        el.classList.remove("visible");
      });
      this.hasEnded = true;
      this.showArrowInS(2);
    } else {
      this.textEls[this.clickCount].classList.add("visible");
      this.clickCount++;
    }
  }
  destroy() {
    this.el.removeEventListener("mousemove", this.moveListener);
    super.destroy();
  }
  start() {
    super.start();
    this.initRandomChild();
    // this.child = this.children[0];

    this.moveListener = this.el.addEventListener("mousemove", (e) => {
      this.handleMouseMove(e);
    });
    this.chairStyles = {
      scaleY: [1, 1],
      skew: [0, 1],
    };
    requestAnimationFrame(() => {
      this.animate();
    });

    setTimeout(() => {
      if (!this.hasClicked) {
        this.textEls[this.clickCount].classList.add("visible");
        this.clickCount++;
      }
    }, 3000);
  }
}
