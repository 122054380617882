import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class NeonArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    const bgImg01 = this.data.props.bg01;
    const bgImg02 = this.data.props.bg02;
    const imgEl = document.createElement("img");
    const imgOverlayEl = document.createElement("img");
    imgEl.src = bgImg01;
    imgOverlayEl.src = bgImg02;
    if (this.helpers.isWindowPortrait()) {
      imgEl.style.width = "100%";
      imgEl.style.height = "auto";
      this.el.style.height = "auto";
    } else {
      imgEl.style.width = "auto";
      imgEl.style.height = "100%";
      this.el.style.width = "auto";
    }
    imgEl.style.display = "block";
    imgOverlayEl.style.display = "block";
    this.el.append(imgEl);
    this.el.append(imgOverlayEl);

    imgOverlayEl.classList.add("container-full");
    imgOverlayEl.classList.add("neon-overlay");

    this.parent.classList.add("centercontent");
    this.parent.style.backgroundColor = "#fff";
    this.el.classList.add("artwork--relative");
    this.el.classList.add("artwork--perspective");

    this.initNeon();
  }
  initNeon() {
    let neonWrap = document.createElement("div");
    neonWrap.classList.add("container-full");
    neonWrap.classList.add("neon-wrap");
    this.el.append(neonWrap);

    let neons = [];

    for (let i = 1; i <= 4; i++) {
      let neonEl = document.createElement("a");
      neons.push(neonEl);
      neonEl.classList.add("neon-element");
      neonEl.classList.add("neon-element--" + i);
      neonEl.href = "#";
      let neonImg = document.createElement("img");
      neonImg.src = this.data.props.splitter[i - 1];
      neonImg.style.width = "100%";
      neonImg.style.height = "auto";
      neonEl.append(neonImg);
      neonWrap.append(neonEl);

      neonEl.addEventListener("mouseover", (e) => {
        e.preventDefault();

        neons.forEach((n) => {
          n.classList.remove("active");
          setTimeout(() => {
            if (!n.classList.contains("active")) {
              n.classList.remove("nopointer");
            }
          }, 1000);
        });
        e.currentTarget.classList.add("active");
        e.currentTarget.classList.add("nopointer");
        this.el.classList.add("inactive");

        setTimeout(() => {
          this.el.classList.remove("inactive");
        }, 1000);

        setTimeout(() => {
          // this.animateOut();
        }, 4000);
      });
    }
  }
  start() {
    this.showArrowInS(5);
    super.start();
    this.initChildren();
  }
}
