import helpers from "./helpers";

export default class ImageLoader {
  constructor(images) {
    this.preloadImages(images);
  }
  preloadImages(images) {
    let count = 0;

    images.forEach((_src) => {
      let img = new Image();
      img.src = _src;
      img.onload = function () {
        count++;

        if(count >= images.length) {
          console.log('🏞 all images loaded');
        }
      };
    });
  }
}
