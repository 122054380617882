import helpers from "./helpers";
import ImageLoader from "./ImageLoader";

const appEl = document.querySelector("#app");
const isSafari =
  navigator.userAgent.indexOf("Safari") > -1 &&
  navigator.userAgent.indexOf("Chrome") == -1;

const init = () => {
  if (isSafari) window.isMuted = true;

  const artworkEl = document.createElement("div");
  artworkEl.classList.add("fullsize");
  appEl.append(artworkEl);

  // const firstId = "area-expo";
  const firstId = "magnify";

  const artwork = helpers.getChildArtwork(firstId, artworkEl);
  if (artwork) artwork.start();

  // helpers.insertControls();
  helpers.initRatioCheck();
};

if (helpers.checkBrowser()) {
  appEl.innerHTML =
    '<div class="info-msg"><div><p>Sorry, you need a desktop browser.</p><p><a href="https://www.archives-du-futur.com">Archives du futur</a></p></div></div>';
} else {
  new ImageLoader(helpers.getPreloadImages());

  const infoMsg = document.createElement("div");
  infoMsg.classList.add("info-msg");
  infoMsg.innerHTML = "<div><p>Please turn sound on.</p></div>";
  appEl.append(infoMsg);

  setTimeout(() => {
    infoMsg.classList.add("fade-out");

    setTimeout(() => {
      infoMsg.remove();
    }, 7000);
  }, 1000);

  init();
}
