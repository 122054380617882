import Artwork from "../Artwork";

export default class VideoArtwork extends Artwork {
  init() {
    super.init();
    // this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    this.video =
      this.data.props.files[
        Math.floor(Math.random() * this.data.props.files.length)
      ];
    this.videoEl = document.createElement("video");
    // this.videoEl.loop = "true";
    this.videoEl.src = this.video;
    this.videoEl.setAttribute("playsinline", "");
    this.videoEl.classList.add("fullsize");
    this.videoEl.style.pointerEvents = "none";
    this.el.append(this.videoEl);

    this.videoEl.addEventListener("ended", () => {
      this.animateOut();
    });

    if (this.data.props.background && this.data.props.background == "white") {
      this.el.classList.add("artwork--white");
    }
    if (this.data.props.ratio) {
      this.initFill();
    }

    this.initLoading();
  }
  initLoading() {
    this.loading = document.createElement("div");
    this.loading.classList.add("video-loading");
    this.loading.innerText = "loading...";
    this.el.append(this.loading);
  }
  checkAudio() {
    if (window.isMuted) {
      this.videoEl.muted = true;
      this.audioButton.classList.add("muted");
    } else {
      this.videoEl.muted = false;
      this.audioButton.classList.remove("muted");
    }
  }
  initAudioButton() {
    this.audioButton = document.createElement("button");
    this.audioButton.classList.add("audio-button");
    this.audioButton.addEventListener("click", () => {
      window.isMuted = !window.isMuted;
      this.checkAudio();
    });
    this.el.append(this.audioButton);

    const proceedLink = document.createElement("div");
    this.el.append(proceedLink);
    proceedLink.classList.add("proceed-link");
    proceedLink.addEventListener("click", () => {
      this.animateOut();
    });
  }
  start() {
    this.initChildren();
    super.start();

    if (this.data.props.muted != "true") {
      this.initAudioButton();
      this.checkAudio();
    }

    this.videoEl.addEventListener("playing", () => {
      this.loading.remove();
    });
    this.videoEl.play();
    this.videoEl.volume = 0.5;

    this.showArrowInS(10);
  }
  destroy() {
    window.removeEventListener("resize", this.resizeChecker);
    super.destroy();
  }
  initFill() {
    const ratio = this.data.props.ratio;
    const checkRatio = () => {
      if (window.innerWidth / window.innerHeight > ratio) {
        this.videoEl.classList.add("wide");
        this.videoEl.classList.remove("high");
      } else {
        this.videoEl.classList.remove("wide");
        this.videoEl.classList.add("high");
      }
    };
    checkRatio();
    this.resizeChecker = window.addEventListener("resize", checkRatio);
  }
}
