import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class SlideArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    this.image =
      this.data.props.files[
        Math.floor(Math.random() * this.data.props.files.length)
      ];
    this.initCanvas();
    this.el.classList.add("artwork--white");
  }
  initCanvas() {
    this.canvas = document.createElement("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.canvas.classList.add("slide-canvas");
    this.canvas.classList.add("fullsize");
    this.el.append(this.canvas);

    this.ctx = this.canvas.getContext("2d", { alpha: false });
    this.img = new Image();
    this.img.onload = () => {
      this.ratio = this.img.width / this.img.height;

      this.ctx.drawImage(
        this.img,
        0,
        0,
        this.canvas.height * this.ratio,
        this.canvas.height
      );
    };
    this.img.src = this.image;
    this.imgX = 0;

    this.resizer = window.addEventListener("resize", () => {
      this.handleResize();
    });
  }
  handleResize() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
  }
  animate() {
    this.imgX -= this.img.width / 2000;
    this.ctx.beginPath();
    this.ctx.fillStyle = "white";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.closePath();

    this.ctx.drawImage(
      this.img,
      0,
      0,
      this.img.width,
      this.img.height,
      this.imgX,
      0,
      this.canvas.height * this.ratio,
      this.canvas.height
    );
    if (this.imgX < -this.canvas.height * this.ratio) {
      this.animating = false;
      this.animateOut();
    }

    if (!this.animating) return;
    requestAnimationFrame(() => {
      this.animate();
    });
  }
  start() {
    super.start();
    this.initChildren();
    this.animating = true;
    this.animate();
  }
  destroy() {
    window.removeEventListener("resize", this.resizer);
    // window.clearTimeout(this.timer);
    super.destroy();
  }
}
