import Artwork from "../Artwork";
import template from "~assets/spotlight-template.txt";

export default class SpotlightArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
  }
  initArtwork() {
    const image =
      this.data.props.files[
        Math.floor(Math.random() * this.data.props.files.length)
      ];

    this.lightStyles = {
      translateX: [0.5, 0.5],
      translateY: [0.5, 0.5],
    };

    this.initCanvas();

    this.el.style.backgroundImage = `url('${image}')`;
    this.el.classList.add("spotlight-bg");
  }

  initCanvas() {
    this.canvas = document.createElement("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.canvas.classList.add("spotlight-canvas");
    this.canvas.classList.add("fullsize");
    this.el.append(this.canvas);

    this.ctx = this.canvas.getContext("2d", { alpha: true });
    this.img = new Image();
    this.img.onload = () => {
      this.ratio = this.img.width / this.img.height;

      this.ctx.drawImage(
        this.img,
        0,
        0,
        this.canvas.height * this.ratio,
        this.canvas.height
      );
    };
    this.img.src = this.image;
  }
  handleResize() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
  }

  handleMouseMove(e) {
    let mouseX = e.clientX / window.innerWidth;
    let mouseY = e.clientY / window.innerHeight;

    this.lightStyles.translateX[1] = mouseX;
    this.lightStyles.translateY[1] = mouseY;
  }
  animate() {
    this.lightStyles.translateX[0] +=
      (this.lightStyles.translateX[1] - this.lightStyles.translateX[0]) / 5;

    this.lightStyles.translateY[0] +=
      (this.lightStyles.translateY[1] - this.lightStyles.translateY[0]) / 5;

    let w = this.canvas.width;
    let h = this.canvas.height;
    let cx = this.lightStyles.translateX[0] * w;
    let cy = this.lightStyles.translateY[0] * h;
    // let radius = 200;
    let radius = (w + h) / 18;

    this.ctx.save();
    this.ctx.clearRect(0, 0, w, h);

    var radialGradient = this.ctx.createRadialGradient(
      cx,
      cy,
      1,
      cx,
      cy,
      radius
    );

    radialGradient.addColorStop(0, "rgba(0, 0, 0, 1)");
    radialGradient.addColorStop(0.4, "rgba(0, 0, 0, 1)");
    radialGradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    this.ctx.beginPath();

    this.ctx.fillStyle = "#000";
    this.ctx.fillRect(0, 0, w, h);

    this.ctx.globalCompositeOperation = "destination-out";

    this.ctx.arc(cx, cy, radius, 0, Math.PI * 2, false);
    this.ctx.fillStyle = radialGradient;
    this.ctx.fill();

    this.ctx.restore();

    if (this.finished) return;
    requestAnimationFrame(() => {
      this.animate();
    });
  }
  destroy() {
    this.finished = true;
    this.el.removeEventListener("mousemove", this.moveListener);
    window.removeEventListener("resize", this.resizeListener);
    super.destroy();
  }
  start() {
    this.initChildren();
    super.start();

    this.moveListener = this.el.addEventListener("mousemove", (e) => {
      this.handleMouseMove(e);
    });
    this.resizeListener = window.addEventListener("resize", (e) => {
      this.handleResize();
    });

    this.hideCursor();

    this.showArrowInS(5);

    this.animate();
  }
}
