import data from "./data.json";
import SlideshowArtwork from "./artworks/SlideshowArtwork";
import VideoArtwork from "./artworks/VideoArtwork";
import MagnifyArtwork from "./artworks/MagnifyArtwork";
import ExpoAreaArtwork from "./artworks/ExpoAreaArtwork";
import SlideArtwork from "./artworks/SlideArtwork";
import ZoomoutArtwork from "./artworks/ZoomoutArtwork";
import ImagewallArtwork from "./artworks/ImagewallArtwork";
import NeonArtwork from "./artworks/NeonArtwork";
import RoundVideoArtwork from "./artworks/RoundVideoArtwork";
import MemoryArtwork from "./artworks/MemoryArtwork";
import ChairArtwork from "./artworks/ChairArtwork";
import WordsArtwork from "./artworks/WordsArtwork";
import SpotlightArtwork from "./artworks/SpotlightArtwork";
import AudioImageArtwork from "./artworks/AudioImageArtwork";

export default {
  log() {
    console.log(data);
  },
  getPreloadImages() {
    return data.preload;
  },
  getArtworkById(_id) {
    return data.artworks.find((el) => {
      return el.id == _id;
    });
  },
  isWindowPortrait() {
    if (window.innerWidth < window.innerHeight) {
      return true;
    }
    return false;
  },
  getChildArtwork(_id, parentEl) {
    const childData = this.getArtworkById(_id);
    // const parentEl = document.querySelector('#app');
    if (!childData) return;
    switch (childData.effect) {
      case "slideshow":
        return new SlideshowArtwork(parentEl, childData);
        break;
      case "video":
        return new VideoArtwork(parentEl, childData);
        break;
      case "magnify":
        return new MagnifyArtwork(parentEl, childData);
        break;
      case "expo":
        return new ExpoAreaArtwork(parentEl, childData);
        break;
      case "slide":
        return new SlideArtwork(parentEl, childData);
        break;
      case "zoomout":
        return new ZoomoutArtwork(parentEl, childData);
        break;
      case "imagewall":
        return new ImagewallArtwork(parentEl, childData);
        break;
      case "neon":
        return new NeonArtwork(parentEl, childData);
        break;
      case "roundvideo":
        return new RoundVideoArtwork(parentEl, childData);
        break;
      case "memory":
        return new MemoryArtwork(parentEl, childData);
        break;
      case "chair":
        return new ChairArtwork(parentEl, childData);
        break;
      case "words":
        return new WordsArtwork(parentEl, childData);
        break;
      case "spotlight":
        return new SpotlightArtwork(parentEl, childData);
        break;
      case "audioimage":
        return new AudioImageArtwork(parentEl, childData);
        break;
    }
    return null;
  },
  checkBrowser() {
    const ua = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
    return isMobile;
  },
  insertControls() {
    const controlsEl = document.createElement("div");
    controlsEl.classList.add("controls");

    const controlsBtn = document.createElement("button");
    controlsBtn.classList.add("controls__toggle");
    controlsBtn.innerText = "☝️";
    controlsEl.append(controlsBtn);

    const controlsList = document.createElement("div");
    controlsList.classList.add("controls__list");

    controlsBtn.addEventListener("click", (e) => {
      controlsList.classList.toggle("controls__list--visible");
    });

    data.artworks.forEach((art) => {
      let artEl = document.createElement("button");
      artEl.innerText = art.id;
      controlsList.append(artEl);

      artEl.addEventListener("click", (e) => {
        const appEl = document.querySelector("#app");
        const artworkEl = document.createElement("div");

        appEl.innerHTML = "";
        artworkEl.classList.add("fullsize");
        appEl.append(artworkEl);

        const firstId = art.id;

        const artwork = this.getChildArtwork(firstId, artworkEl);
        if (artwork) artwork.start();

        controlsList.classList.remove("controls__list--visible");
      });
    });

    controlsEl.append(controlsList);

    document.body.append(controlsEl);
  },

  initRatioCheck() {
    const checkRatio = () => {
      if (window.innerWidth >= window.innerHeight) {
        document.body.classList.remove("portrait");
        document.body.classList.add("landscape");
      } else {
        document.body.classList.add("portrait");
        document.body.classList.remove("landscape");
      }
    };
    checkRatio();

    window.addEventListener('resize', checkRatio);
  },

  toggleAudio() {
    window.isMuted = !window.isMuted;
  }
};
