import Artwork from "../Artwork";
import template from "~assets/spotlight-template.txt"

export default class AudioImageArtwork extends Artwork {
  init() {
    super.init();
    this.initArtwork();
  }
  initArtwork() {
    const imageFile = this.data.props.image;
    const audioFile = this.data.props.audio;

    this.imageEl = new Image();
    this.imageEl.classList.add("fullsize");
    this.imageEl.classList.add("audioimage-image");
    this.imageEl.src = imageFile;
    this.el.append(this.imageEl);

    this.audioEl = document.createElement("audio");
    this.audioEl.setAttribute("src", audioFile);
    this.audioEl.loop = "true";
    this.el.append(this.audioEl);
  }
  start() {
    this.audioEl.play();
    this.initChildren();

    this.initAudioButton();
    this.checkAudio();

    this.showArrowInS(7);
    
    super.start();
  }
  checkAudio() {
    if (window.isMuted) {
      this.audioEl.muted = true;
      this.audioButton.classList.add("muted");
    } else {
      this.audioEl.muted = false;
      this.audioButton.classList.remove("muted");
    }
  }
  initAudioButton() {
    this.audioButton = document.createElement("button");
    this.audioButton.classList.add("audio-button");
    this.audioButton.addEventListener("click", () => {
      window.isMuted = !window.isMuted;
      this.checkAudio();
    });
    this.el.append(this.audioButton);

    const proceedLink = document.createElement("div");
    this.el.append(proceedLink);
    proceedLink.classList.add("proceed-link");
    proceedLink.addEventListener("click", () => {
      this.animateOut();
    });
  }
}
