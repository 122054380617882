import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

import areas from "../../assets/expo-areas.txt";
import { SVG } from "@svgdotjs/svg.js";

export default class ExpoAreaArtwork extends Artwork {
  init() {
    super.init();
    this.initArtwork();
  }
  initArtwork() {
    const image = this.data.props.files[0];
    const svgFile = this.data.props.files[1];
    const imgEl = document.createElement("img");
    imgEl.src = image;
    if (this.helpers.isWindowPortrait()) {
      imgEl.style.width = "100%";
      imgEl.style.height = "auto";
      this.el.style.height = "auto";
    } else {
      imgEl.style.width = "auto";
      imgEl.style.height = "100%";
      this.el.style.width = "auto";
    }
    imgEl.style.display = "block";
    this.el.append(imgEl);

    this.parent.classList.add("centercontent");
    this.parent.style.backgroundColor = "#000";
    this.el.classList.add("artwork--relative");

    this.draw = SVG().addTo(this.el);
    this.draw.node.classList.add("fullsize");
    this.draw.node.classList.add("expo-svg");
    this.draw.viewbox(0, 0, 2000, 1333);
    this.draw.svg(
      '<g><path fill="#fff" id="blue" d="m647.4 0v277h-4.7v15.7c0 6.9-8.3 9.3-8.6 16.3-0.3 5.7 0.7 358.9 0.7 358.9l-151.9-14.7-482.9 181.5v-834.7h647.4zm1352.6 0v753.6l-972.3-58.1 1-388.9c-7.6-1.3-12.3-9.9-11.2-30.7l-3.1-0.4v-275.5h985.6z"/><path fill="#fff" id="yellow" d="m770.4 517.1 93.5 4.8 0.1-10.1h-5.4s1.3-7.5-0.9-11.1c-2.2-3.5-4.4-4.9-4.4-4.9l-0.5-192.9s4.3-3.9 5.2-7.1c0.9-3.1 0.6-10.2 0.6-10.2l2.5-0.2v-226.5l-92.3-37.8-0.5 263.5 2 0.1 0.1 232.4z"/><path fill="#fff" id="orange" d="m697.7 492.6 0.7 135.7-41.4 17.8-1-141.8 41.7-11.7zm316.7-492.6v279.2h-4.3l-0.8 207.5-48.7-1.5-96.7 36.7 0.1-10.1 0.5-15.6 1.8-0.3v-193.6l-2.2-5.8v-11h-2.9v-226.6l-92.3-37.8-0.5 263.5h-1.4v12l-3.3 9.8v216.3c0 1.0484 0.60159 3.6576 1.3196 6.3986l0.17431 0.65961 0.17729 0.66028c0.98058 3.6253 2.0288 7.0816 2.0288 7.0816l0.3 20.8-60.8 22.2-0.4-302.1h-4.5v16.1c0 0.91837-1.0673 3.2945-2.1127 5.4017l-0.24994 0.4997c-0.91082 1.8068-1.7373 3.2986-1.7373 3.2986l0.1 15.2-40.6 0.3s0.097117-3.3667-0.003349-6.7848l-0.020509-0.62129c-0.087468-2.3781-0.27959-4.6836-0.67614-5.7939-1-2.8-4.4-10.1-4.4-11.6v-17.4l-4.9-0.1v-276.9h367zm-316.7 337.7v136.6l-41.7 10.2v-144.7l41.7-2.1z"/></g>'
    );

    const paths = this.draw.find("path");
    paths.forEach((path) => {
      path.click((e) => {
        let el = e.currentTarget;
        this.proceed(el.id);
      });
    });

    this.notice = document.createElement("div");
    this.notice.classList.add("expo-notice");
    this.notice.innerText = "Choose a color";
    this.el.append(this.notice);

    this.initVideo();
  }
  initVideo() {
    const video = this.data.props.video;
    const videoEl = document.createElement("video");
    videoEl.src = video;
    videoEl.classList.add("expo-video");
    videoEl.loop = "true";
    videoEl.muted = "true";
    this.el.append(videoEl);
    this.video = videoEl;
  }
  proceed(_color) {
    let childId = "";
    switch (_color) {
      case "yellow":
        childId = 0;
        break;
      case "blue":
        childId = 1;
        break;
      case "orange":
        childId = 2;
        break;
    }
    this.initChildById(childId);
    this.animateOut();
  }
  start() {
    this.notice.classList.add('animated');
    this.video.play();
    super.start();
  }
}
