module.exports = `<svg
  class="words-svg fullsize"
  preserveAspectRatio="none"
  viewBox="0 0 1405 1336"
  id="svg"
>
  <mask id="myMask">
    <polygon
      id="maskbg"
      points="0 0 1405 0 1405 1336 0 1336"
      fill="#ffffff"
    ></polygon>
    <circle
      id="maskpoly"
      cx="0"
      cy="0"
      r="220"
      fill="url('#myGradient')"
      style="transform: translateX(99.7143%) translateY(10.7786%)"
    ></circle>
  </mask>
  <polygon
    points="0 0 1405 0 1405 1336 0 1336"
    fill="#000000"
    mask="url(#myMask)"
    id="poly"
  ></polygon>
  <defs>
    <radialGradient id="myGradient">
      <stop offset="20%" stop-color="#000000" />
      <stop offset="100%" stop-color="#ffffff" />
    </radialGradient>
  </defs>
</svg>
`