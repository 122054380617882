import Artwork from "../Artwork";
import anime from "animejs/lib/anime.es.js";

export default class MagnifyArtwork extends Artwork {
  init() {
    super.init();
    this.enableClickProceed();
    this.initArtwork();
    this.activated = false;
    this.finished = false;
  }
  initArtwork() {
    this.image = this.data.props.files[
      Math.floor(Math.random() * this.data.props.files.length)
    ];

    // this.el.classList.add("bg-contain");
    this.el.classList.add("magnifier-wrap");
    this.el.style.backgroundImage = `url(${this.image})`;

    this.magnifier = document.createElement("div");
    this.el.append(this.magnifier);
    this.magnifier.classList.add("magnifier");
    this.magnifierDiameter = this.magnifier.clientWidth;

    this.magnifierAw = document.createElement("img");
    this.magnifier.append(this.magnifierAw);
    this.magnifierAw.src = this.image;
    this.magnifierAw.classList.add("magnifier-aw");

    this.magnifierPos = { x: 0, y: 0 };
    this.mousePos = { x: 0, y: 0 };
  }
  recordMouse(e) {
    if (!this.activated && !this.finished) {
      setTimeout(() => {
        this.el.classList.add("active");
      }, 300);
      this.activated = true;
    }
    this.mousePos.x = e.clientX;
    this.mousePos.y = e.clientY;
  }
  animate() {
    this.magnifierPos.x += (this.mousePos.x - this.magnifierPos.x) / 5;
    this.magnifierPos.y += (this.mousePos.y - this.magnifierPos.y) / 5;
    let t = "translate(-50%, -50%) ";
    t += `translate(${this.magnifierPos.x}px, ${this.magnifierPos.y}px)`;
    this.magnifier.style.transform = t;

    let xPerc = (this.magnifierPos.x / window.innerWidth) * -100;
    // console.log(xPerc);
    let yPerc = (this.magnifierPos.y / window.innerHeight) * -100;
    let tAw = `translate(${xPerc}%, ${yPerc}%)`;
    this.magnifierAw.style.transform = tAw;

    requestAnimationFrame(() => {
      this.animate();
    });
  }
  animateOut() {
    document.removeEventListener("mousemove", this.mouseListener);
    this.el.classList.remove("active");
    this.finished = true;
    super.animateOut();
  }
  start() {
    super.start();
    this.initChildren();

    this.el.style.zIndex = 1;
    this.mouseListener = document.addEventListener("mousemove", (e) => {
      this.recordMouse(e);
    });
    requestAnimationFrame(() => {
      this.animate();
    });
    this.hideCursor();

    this.showArrowInS(5);
  }
  initChildren() {
    this.initRandomChild();
  }
}
