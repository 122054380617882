module.exports = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2000 1333" style="enable-background:new 0 0 2000 1333;" xml:space="preserve">
<style type="text/css">
	.st0{opacity:0.52;fill-rule:evenodd;clip-rule:evenodd;fill:#54B5E2;enable-background:new    ;}
	.st1{opacity:0.4;fill-rule:evenodd;clip-rule:evenodd;fill:#FF960E;enable-background:new    ;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFE967;}
</style>
<g id="BLAU">
	<path id="blue" class="st0" d="M647.4,0v277h-4.7c0,0,0,8.8,0,15.7c0,6.9-8.3,9.3-8.6,16.3c-0.3,5.7,0.7,358.9,0.7,358.9
		l-151.9-14.7L0,834.7V0H647.4z M2000,0v753.6l-972.3-58.1l1-388.9c-7.6-1.3-12.3-9.9-11.2-30.7c-3.1-0.4-3.1-0.4-3.1-0.4V0H2000z"
		/>
</g>
<g id="ORANGE">
	<g>
		<path class="st1" d="M647.4,0v277l4.9,0.1c0,0,0,15.9,0,17.4s3.4,8.8,4.4,11.6c1,2.8,0.7,13.2,0.7,13.2L698,319l-0.1-15.2
			c0,0,4.1-7.4,4.1-9.2c0-1.8,0-16.1,0-16.1h4.5l0.4,302.1l60.8-22.2l-0.3-20.8c0,0-3.7-12.2-3.7-14.8s0-216.3,0-216.3l3.3-9.8l0-12
			h1.4l0.5-263.5L861.2,59v226.6h2.9v11l2.2,5.8v193.6l-1.8,0.3l-0.5,15.6l-0.1,10.1l96.7-36.7l48.7,1.5l0.8-207.5l4.3,0l0-279.2
			H647.4z"/>
		<polygon class="st1" points="697.7,337.8 656,339.9 656,484.6 697.7,474.4 		"/>
		<polygon class="st1" points="656,504.4 657,646.2 698.4,628.4 697.7,492.7 		"/>
	</g>
</g>
<g id="GELB">
	<path class="st2" d="M770.4,517.2l93.5,4.8l0.1-10.1h-5.4c0,0,1.3-7.5-0.9-11.1c-2.2-3.5-4.4-4.9-4.4-4.9L852.8,303
		c0,0,4.3-3.9,5.2-7.1c0.9-3.1,0.6-10.2,0.6-10.2l2.5-0.2V59l-92.3-37.8l-0.5,263.5l2,0.1L770.4,517.2z"/>
</g>
</svg>
`